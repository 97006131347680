class Dialog {
  /**
   * Initializes a new instance of the Dialog class.
   * @param {Object} settingsObj - An object containing the settings for the dialog.
   * @param {HTMLElement} settingsObj.button - The dialog button element.
   * @param {HTMLElement} settingsObj.target - The dialog target element.
   * @param {HTMLElement} settingsObj.close - The dialog close element.
   */
  constructor(settingsObj) {
    this.settings = settingsObj;

    this.dialogButton = this.settings.button || document.querySelector('[data-dialog]');
    if (!this.dialogButton) {
      console.warn('No Dialog Button');
      return;
    }

    this.dialogTarget = this.settings.target || document.querySelector(`#${this.dialogButton.dataset.modal}`);
    if (!this.dialogTarget) {
      console.warn('No Dialog Target');
      return;
    }

    this.dialogClose = this.settings.close || this.dialogTarget.querySelector('[class*="close"], [class*="Close"], [aria-label*="close"]');
    if (!this.dialogClose) {
      console.warn('No Dialog Close');
      return;
    }

    this.curState = false;
    this.isNativeDialog = this.dialogTarget.tagName.toLowerCase() === 'dialog';

    // Set initial ARIA attributes
    this.dialogTarget.setAttribute('role', 'dialog');
    this.dialogTarget.setAttribute('aria-modal', 'true');
    this.dialogTarget.setAttribute('aria-hidden', 'true');

    // Bind event handlers
    this.boundKeypressHandler = this.keypressHandler.bind(this);
    this.boundToggleDialog = this.toggleDialogVisibility.bind(this);
    this.boundEscapeHandler = this.escapeHandler.bind(this);

    this.init();
  }

  init() {
    // Single click handler for both mouse clicks and keyboard activation
    this.dialogButton.addEventListener('click', this.boundToggleDialog);
    this.dialogClose.addEventListener('click', this.boundToggleDialog);
  }

  escapeHandler(e) {
    if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
      this.toggleDialogVisibility();
    }
  }

  toggleDialogVisibility(event) {
    // Only prevent default if event exists and currentTarget is an anchor
    if (event?.currentTarget?.tagName?.toLowerCase() === 'a') {
      event.preventDefault();
    }

    this.curState = !this.curState;

    this.dialogButton.setAttribute('aria-expanded', this.curState);
    this.dialogTarget.classList.toggle('Dialog--open');
    this.dialogTarget.setAttribute('aria-hidden', (!this.curState).toString());

    if (this.curState) {
      this.openDialog();
    } else {
      this.closeDialog();
    }
  }

  openDialog() {
    // Store last focused element to restore later
    this.lastActiveElement = document.activeElement;

    // Make dialog visible first
    if (this.isNativeDialog) {
      this.dialogTarget.showModal();
    } else {
      this.dialogTarget.style.display = 'block';
    }

    // Update focusable elements after dialog is visible
    this.updateFocusableElements();

    // Set focus after a brief delay to ensure dialog is visible
    setTimeout(() => {
      if (this.focusableElements.length > 0) {
        this.firstFocusableEl.focus();
      } else {
        this.dialogTarget.focus();
      }
    }, 100);

    // Add event listeners
    this.dialogTarget.addEventListener('keydown', this.boundKeypressHandler);
    document.addEventListener('keydown', this.boundEscapeHandler);
    document.body.classList.add('no-scroll');

    // Handle videos if present
    const video = this.dialogTarget.querySelector('video');
    if (video) {
      video.play();
    }
  }

  closeDialog() {
    // Handle iframes
    const iframe = this.dialogTarget.querySelector('iframe');
    if (iframe) {
      const src = iframe.src;
      iframe.src = '';
      iframe.src = src;
    }

    // Handle videos
    Array.from(this.dialogTarget.querySelectorAll('video')).forEach(el => {
      el.pause();
      el.currentTime = 0;
    });

    if (this.isNativeDialog) {
      this.dialogTarget.close();
    } else {
      this.dialogTarget.style.display = 'none';
    }

    // Remove event listeners
    this.dialogTarget.removeEventListener('keydown', this.boundKeypressHandler);
    document.removeEventListener('keydown', this.boundEscapeHandler);
    document.body.classList.remove('no-scroll');

    // Restore focus to the last active element
    if (this.lastActiveElement) {
      this.lastActiveElement.focus();
    }
  }

  updateFocusableElements() {
    // Comprehensive selector for all potentially focusable elements
    const focusableSelector = [
      'a[href]',
      'button',
      'input',
      'select',
      'textarea',
      '[contenteditable]',
      '[tabindex]:not([tabindex="-1"])',
      'iframe',
      'object',
      'embed',
      'audio[controls]',
      'video[controls]',
      '[role="button"]',
      '[role="textbox"]',
      '[role="combobox"]',
      '[role="listbox"]'
    ].join(',');

    // Get all focusable elements and filter out disabled/hidden ones
    this.focusableElements = Array.from(this.dialogTarget.querySelectorAll(focusableSelector))
      .filter(el => {
        const style = window.getComputedStyle(el);
        return !el.hasAttribute('disabled') &&
               !el.hasAttribute('hidden') &&
               style.display !== 'none' &&
               style.visibility !== 'hidden' &&
               style.opacity !== '0';
      });

    this.firstFocusableEl = this.focusableElements[0];
    this.lastFocusableEl = this.focusableElements[this.focusableElements.length - 1];

    // Debug log
    //console.log('Focusable elements updated:', this.focusableElements);
  }

  keypressHandler(event) {
    const isTab = event.key === 'Tab' || event.keyCode === 9;

    // Debug log
    //console.log('Keypress handler called:', event.key);

    if (!isTab) {return;}

    // Handle focus trap
    if (this.focusableElements.length === 0) {
      event.preventDefault();
      return;
    }

    // Always prevent the default tab behavior
    event.preventDefault();

    const activeElement = document.activeElement;
    //console.log('Active element:', activeElement);

    if (event.shiftKey) {
      // If shift+tab
      if (activeElement === this.firstFocusableEl || activeElement === this.dialogTarget) {
        // If at start, move to end
        this.lastFocusableEl.focus();
      } else {
        // Move to previous focusable element
        const currentIndex = this.focusableElements.indexOf(activeElement);
        const previousElement = this.focusableElements[currentIndex - 1] || this.lastFocusableEl;
        previousElement.focus();
      }
    } else {
      // If tab
      if (activeElement === this.lastFocusableEl) {
        // If at end, move to start
        this.firstFocusableEl.focus();
      } else {
        // Move to next focusable element
        const currentIndex = this.focusableElements.indexOf(activeElement);
        const nextElement = this.focusableElements[currentIndex + 1] || this.firstFocusableEl;
        nextElement.focus();
      }
    }
  }
}

export default Dialog;

// EOF
